import React from "react";
import "../css/Login.css";
import { post } from "../utils/connection";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      passwd: "",
      errorLoginMsg: false,
    };
  }

  nameChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  passwdChange = (event) => {
    this.setState({
      passwd: event.target.value,
    });
  };

  checkLogin = async () => {
    if (this.state.name !== "" && this.state.passwd !== "") {
      const response = await post("Login", {
        usuario: this.state.name,
        passwd: this.state.passwd,
      });
      if (response.error !== "") {
        // Error Login
        this.setState({
          errorLoginMsg: true,
        });
      } else {
        // Login OK
        this.setState({
          errorLoginMsg: false,
        });
        localStorage.setItem("token", response.token);
        window.location = "/panel";
      }
    }
  };

  closeErrorMessage = () => {
    this.setState({
      errorLoginMsg: false,
    });
  };

  render() {
    return (
      <div className="loginbody Login-loginbody">
        <img
          src="images/logo.png"
          loading="lazy"
          srcSet="images/logo.png 500w, images/logo.png 800w, images/logo.png 800w"
          sizes="200.00001525878906px"
          alt=""
          className="logofsf"
        />
        <h1 className="loteriatitle loginscreen">Lotería Navidad</h1>
        <div className="logincard Login-logincard">
          <h2 className="formheader loginscreen">
            <span>
              <strong className="boldtext">Inicia sesión</strong>
            </span>
          </h2>
          <div className="form-block w-form Login-form-block">
            <label htmlFor="name" className="field-label">
              Usuario
            </label>
            <input
              type="text"
              className="text-field w-input"
              maxLength="256"
              value={this.state.name}
              onChange={this.nameChange}
            ></input>
            <label htmlFor="email" className="field-label">
              Contraseña
            </label>
            <input
              type="password"
              className="text-field w-input"
              maxLength="256"
              value={this.state.passwd}
              onChange={this.passwdChange}
            ></input>
            {this.state.errorLoginMsg && (
              <div className="alertmessage warningmessage slimMargin">
                <div className="dialogbox">
                  <span>
                    La <strong>contraseña</strong> y/o el{" "}
                    <strong>usuario</strong> introducidos son{" "}
                    <strong>incorrectos</strong>.
                  </span>
                </div>
                <img
                  src="images/close-grey.svg"
                  loading="lazy"
                  width="18"
                  alt=""
                  className="image-4 login Login-image-4"
                  onClick={this.closeErrorMessage}
                />
              </div>
            )}
            <div
              className="submit-button w-button Login-submit-button"
              onClick={this.checkLogin}
            >
              <span>Iniciar sesión</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
