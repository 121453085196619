import React from "react";
import "../css/CheckNumber.css";
import { post } from "../utils/connection";

class CheckNumber extends React.Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");
    this.state = {
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      code: "",
      winningNumber: false,
      noWinningNumberMessage: false,
      invalidNumberMessage: false,
      invalidCodeMessage: false,
      usedCodeMessage: false,
      incidenceCodeMessage: false,
      prizeRequestForm: false,
      successfulRequest: false,
      checkNumberContainer: true,
      nombre: "",
      apellidos: "",
      dni: "",
      telefono: "",
      email: "",
      iban: "",
      ibanCodigoPais: "",
      ibanDigitoControl1: "",
      ibanEntidad: "",
      ibanOficina: "",
      ibanDigitoControl2: "",
      ibanNumeroCuenta: "",
      premio: "",
      toggleLegacy: false,
      legacyMessage: false,
      emptyFieldMessage: false,
      fullNumber: "",
      certificadoDonacion: false,
      localidad: "",
      provincia: "",
      direccion: "",
      cp: "",
      maintenance: null,
    };
    this.checkMaintenance();
  }

  checkMaintenance = async () => {
    const response = await post("ComprobarMantenimiento", {});
    this.setState({
      maintenance: response.mantenimiento,
    });
  };

  checkNumber = async () => {
    const fullNumber =
      this.state.num1 +
      this.state.num2 +
      this.state.num3 +
      this.state.num4 +
      this.state.num5;
    if (
      this.state.num1 !== "" &&
      this.state.num2 !== "" &&
      this.state.num3 !== "" &&
      this.state.num4 !== "" &&
      this.state.num5 !== ""
    ) {
      if (!isNaN(fullNumber)) {
        this.setState({
          fullNumber: fullNumber,
        });
        // Valid number
        const response = await post("ComprobarNumero", {
          numero: fullNumber,
        });
        if (response.premio !== 0 && response.premio !== "0") {
          // Winning number
          this.setState({
            premio: response.premio,
            winningNumber: true,
            noWinningNumberMessage: false,
            invalidNumberMessage: false,
            invalidCodeMessage: false,
            usedCodeMessage: false,
            prizeRequestForm: false,
            successfulRequest: false,
          });
        } else {
          // No winning number
          this.setState({
            winningNumber: false,
            noWinningNumberMessage: true,
            invalidNumberMessage: false,
            invalidCodeMessage: false,
            usedCodeMessage: false,
            prizeRequestForm: false,
            successfulRequest: false,
          });
        }
      } else {
        // Invalid number
        this.setState({
          winningNumber: false,
          noWinningNumberMessage: false,
          invalidNumberMessage: true,
          invalidCodeMessage: false,
          usedCodeMessage: false,
          prizeRequestForm: false,
          successfulRequest: false,
        });
      }
    }
  };

  checkCode = async () => {
    if (this.state.code !== "") {
      const response = await post("ComprobarCodigo", {
        numero: this.state.fullNumber,
        codigo: this.state.code,
      });
      if (response.error === "") {
        // Valid code
        this.setState({
          invalidCodeMessage: false,
          usedCodeMessage: false,
          prizeRequestForm: true,
          checkNumberContainer: false,
          incidenceCodeMessage: false,
        });
      } else {
        if (response.error === 1) {
          // Invalid code
          this.setState({
            invalidCodeMessage: true,
            usedCodeMessage: false,
            prizeRequestForm: false,
            incidenceCodeMessage: false,
          });
        } else if (response.error === 2) {
          // Used code
          this.setState({
            invalidCodeMessage: false,
            usedCodeMessage: true,
            prizeRequestForm: false,
            incidenceCodeMessage: false,
          });
        } else if (response.error === 3) {
          // Incidence code
          this.setState({
            invalidCodeMessage: false,
            usedCodeMessage: false,
            prizeRequestForm: false,
            incidenceCodeMessage: true,
          });
        }
      }
    }
  };

  sendForm = async () => {
    if (
      this.state.nombre !== "" &&
      this.state.apellidos !== "" &&
      this.state.dni !== "" &&
      this.state.telefono !== "" &&
      this.state.email !== "" &&
      this.state.ibanCodigoPais !== "" &&
      this.state.ibanCodigoPais.length === 2 &&
      this.state.ibanDigitoControl1 !== "" &&
      this.state.ibanDigitoControl1.length === 2 &&
      this.state.ibanOficina !== "" &&
      this.state.ibanOficina.length === 4 &&
      this.state.ibanEntidad !== "" &&
      this.state.ibanEntidad.length === 4 &&
      this.state.ibanDigitoControl2 !== "" &&
      this.state.ibanDigitoControl2.length === 2 &&
      this.state.ibanNumeroCuenta !== "" &&
      this.state.ibanNumeroCuenta.length === 10 &&
      (!this.state.certificadoDonacion ||
        (this.state.certificadoDonacion &&
          this.state.direccion !== "" &&
          this.state.localidad !== "" &&
          this.state.provincia !== "" &&
          this.state.cp !== ""))
    ) {
      this.setState({
        emptyFieldMessage: false,
      });
      if (this.state.toggleLegacy) {
        this.setState({
          legacyMessage: false,
        });
        const response = await post("EnviarSolicitudTransferencia", {
          numero: this.state.fullNumber,
          codigo: this.state.code,
          nombre: this.state.nombre,
          apellidos: this.state.apellidos,
          dni: this.state.dni,
          email: this.state.email,
          telefono: this.state.telefono,
          iban:
            this.state.ibanCodigoPais +
            this.state.ibanDigitoControl1 +
            this.state.ibanEntidad +
            this.state.ibanOficina +
            this.state.ibanDigitoControl2 +
            this.state.ibanNumeroCuenta,
          certificadoDonacion: this.state.certificadoDonacion === true ? 1 : 0,
          localidad: this.state.localidad,
          provincia: this.state.provincia,
          direccion: this.state.direccion,
          cp: this.state.cp,
        });
        if (response.error === "") {
          this.setState({
            prizeRequestForm: false,
            successfulRequest: true,
            num1: "",
            num2: "",
            num3: "",
            num4: "",
            num5: "",
            code: "",
            fullNumber: "",
            winningNumber: false,
            nombre: "",
            apellidos: "",
            dni: "",
            telefono: "",
            email: "",
            iban: "",
            ibanCodigoPais: "",
            ibanDigitoControl1: "",
            ibanEntidad: "",
            ibanOficina: "",
            ibanDigitoControl2: "",
            ibanNumeroCuenta: "",
            toggleLegacy: false,
            legacyMessage: false,
            emptyFieldMessage: false,
            certificadoDonacion: false,
            localidad: "",
            provincia: "",
            direccion: "",
            cp: "",
          });
        }
      } else {
        this.setState({
          legacyMessage: true,
        });
      }
    } else {
      this.setState({
        emptyFieldMessage: true,
      });
    }
  };

  render() {
    return (
      <div className="bodycontent userview">
        <div className="userheader">
          <img
            src="images/logo.png"
            loading="lazy"
            srcSet="images/logo.png 500w, images/logo.png 800w, images/logo.png 800w"
            sizes="(max-width: 767px) 140px, (max-width: 991px) 160px, 180px"
            alt=""
            className="headerlogo"
          />
          <h1 className="loteriatitle nomargintop mobiledisplaynone">
            Lotería Navidad
          </h1>
          <h1 className="loteriatitle nomargintop mobiledisplay">
            Lotería<br></br>Navidad
          </h1>
        </div>
        {this.state.maintenance === "1" && this.token === null && (
          <div className="mantenimientomensaje">
            <img
              src="images/mantenimiento.svg"
              loading="lazy"
              alt=""
              className="mantenimientoimg"
            ></img>
            <h2 className="mantenimientomensajeheader">
              Estamos realizando labores de{" "}
              <span className="greentxt">mantenimiento</span>.
            </h2>
            <h3 className="mantenimientomensajetxt">
              Vuelva a intentarlo de nuevo más tarde.
            </h3>
          </div>
        )}
        {this.state.maintenance === "2" && this.token === null && (
          <div className="mantenimientomensaje">
            <img
              src="images/finfecha.svg"
              loading="lazy"
              alt=""
              className="mantenimientoimg"
            ></img>
            <h2 className="mantenimientomensajeheader">
              El plazo de comprobación de números ha{" "}
              <span className="greentxt">expirado</span>.
            </h2>
          </div>
        )}
        {(this.state.maintenance === "0" || this.token !== null) &&
          this.state.checkNumberContainer && (
            <div className="formcomprobarcupon w-form">
              <h3 className="comprobarcuponheader">
                <strong>Comprueba</strong> tu <strong>número</strong>
                <strong className="bold-text-2"></strong>
              </h3>
              <div className="comprobarcuponcontent">
                <label
                  htmlFor="name-6"
                  className="comprobarcuponcontenttitle marginbottom CheckNumber-title"
                >
                  Escribe aquí tu número para saber si ha sido premiado.
                </label>
                <div className="codigocupon">
                  <input
                    type="text"
                    className="text-field cuponinput w-input"
                    maxLength="1"
                    value={this.state.num1}
                    onChange={(e) => {
                      this.setState({
                        num1: e.target.value,
                      });
                      e.target?.nextSibling?.focus();
                    }}
                    onFocus={() => {
                      this.setState({
                        num1: "",
                      });
                    }}
                  ></input>
                  <input
                    type="text"
                    className="text-field cuponinput w-input"
                    maxLength="1"
                    value={this.state.num2}
                    onChange={(e) => {
                      this.setState({
                        num2: e.target.value,
                      });
                      e.target?.nextSibling?.focus();
                    }}
                    onFocus={() => {
                      this.setState({
                        num2: "",
                      });
                    }}
                  ></input>
                  <input
                    type="text"
                    className="text-field cuponinput w-input"
                    maxLength="1"
                    value={this.state.num3}
                    onChange={(e) => {
                      this.setState({
                        num3: e.target.value,
                      });
                      e.target?.nextSibling?.focus();
                    }}
                    onFocus={() => {
                      this.setState({
                        num3: "",
                      });
                    }}
                  ></input>
                  <input
                    type="text"
                    className="text-field cuponinput w-input"
                    maxLength="1"
                    value={this.state.num4}
                    onChange={(e) => {
                      this.setState({
                        num4: e.target.value,
                      });
                      e.target?.nextSibling?.focus();
                    }}
                    onFocus={() => {
                      this.setState({
                        num4: "",
                      });
                    }}
                  ></input>
                  <input
                    type="text"
                    className="text-field cuponinput lastitem w-input"
                    maxLength="1"
                    value={this.state.num5}
                    onChange={(e) => {
                      this.setState({
                        num5: e.target.value,
                      });
                      e.target?.nextSibling?.focus();
                    }}
                    onFocus={() => {
                      this.setState({
                        num5: "",
                      });
                    }}
                  ></input>
                </div>
                <div
                  className="submit-button w-button"
                  onClick={this.checkNumber}
                >
                  <span>Comprobar</span>
                </div>
                {this.state.winningNumber && (
                  <div className="CheckNumber-winning-number-container">
                    <div className="alertmessage cuponpremiado">
                      <div className="dialogbox cuponpremiado">
                        <span className="text-span-3">¡Enhorabuena!</span>
                        <br></br>Tu <span className="text-span-4">número</span>{" "}
                        ha sido <span className="text-span-5">premiado</span>.
                      </div>
                      <div className="dialogbox cuponpremiado importepremio">
                        <span className="text-span-9">
                          {this.state.premio} €
                        </span>
                      </div>
                    </div>
                    <label
                      htmlFor="field"
                      className="comprobarcuponcontenttitle marginbottom cuponpremiado Checknumber-title"
                    >
                      Introduce el código de tu participación para solicitar tu
                      premio.
                    </label>
                    <input
                      type="text"
                      value={this.state.code}
                      onChange={(e) => {
                        this.setState({
                          code: e.target.value,
                        });
                      }}
                      className="text-field cuponpremiado w-input"
                      maxLength="256"
                      name="field"
                      data-name=""
                      placeholder="Escriba el código de la participación"
                      id="field"
                      required=""
                    ></input>
                    <div
                      className="submit-button w-button"
                      onClick={this.checkCode}
                    >
                      <span>Solicitar premio</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        {this.state.noWinningNumberMessage && (
          <div className="alertmessage warningmessage">
            <div className="dialogbox">
              El <strong>número</strong> introducido
              <strong> no tiene ningún premio</strong> asignado.
            </div>
            <img
              src="images/close-grey.svg"
              loading="lazy"
              width="18"
              alt=""
              className="icon closeIcon"
              onClick={() => {
                this.setState({
                  noWinningNumberMessage: false,
                  fullNumber: "",
                  num1: "",
                  num2: "",
                  num3: "",
                  num4: "",
                  num5: "",
                });
              }}
            />
          </div>
        )}
        {this.state.invalidNumberMessage && (
          <div className="alertmessage">
            <div className="dialogbox">
              El <strong>número</strong> introducido
              <strong> no es válido</strong>. Por favor, compruébalo e{" "}
              <strong>introdúcelo de nuevo</strong>.
            </div>
            <img
              src="images/close.svg"
              loading="lazy"
              width="18"
              alt=""
              className="icon closeIcon"
              onClick={() => {
                this.setState({
                  invalidNumberMessage: false,
                  fullNumber: "",
                  num1: "",
                  num2: "",
                  num3: "",
                  num4: "",
                  num5: "",
                });
              }}
            />
          </div>
        )}
        {this.state.invalidCodeMessage && (
          <div className="alertmessage warningmessage">
            <div className="dialogbox">
              El <span className="text-span-6">código</span> introducido{" "}
              <span className="text-span-7">no es correcto</span>. Por favor,
              compruébalo e{" "}
              <span className="text-span-8">introdúcelo de nuevo</span>.
            </div>
            <img
              src="images/close-grey.svg"
              loading="lazy"
              width="18"
              alt=""
              className="icon closeIcon"
              onClick={() => {
                this.setState({ invalidCodeMessage: false });
              }}
            />
          </div>
        )}
        {this.state.incidenceCodeMessage && (
          <div className="alertmessage warningmessage">
            <div className="dialogbox">
              Existe una <span className="text-span-6">incidencia</span> con
              esta papeleta. Por favor, póngase en contacto con el{" "}
              <span className="text-span-8">administrador</span>
              en el teléfono <span className="text-span-8">621 08 50 11</span>.
            </div>
            <img
              src="images/close-grey.svg"
              loading="lazy"
              width="18"
              alt=""
              className="icon closeIcon"
              onClick={() => {
                this.setState({ incidenceCodeMessage: false });
              }}
            />
          </div>
        )}
        {this.state.usedCodeMessage && (
          <div className="alertmessage warningmessage">
            <div className="dialogbox">
              El <span className="text-span-6">código</span> introducido{" "}
              <span className="text-span-7">ya ha sido utilizado</span>.
            </div>
            <img
              src="images/close-grey.svg"
              loading="lazy"
              width="18"
              alt=""
              className="icon closeIcon"
              onClick={() => {
                this.setState({ usedCodeMessage: false });
              }}
            />
          </div>
        )}
        {this.state.prizeRequestForm && (
          <div className="formblockdatosusuario w-form">
            <div className="formdatosusuarioheader">
              <h5 className="formheader">
                Rellena el <strong>formulario</strong> para{" "}
                <strong>solicitar tu premio</strong>
              </h5>
            </div>
            <div className="formdatosusuario">
              <label htmlFor="name" className="comprobarcuponcontenttitle">
                Nombre *
              </label>
              <input
                type="text"
                className="text-field w-input"
                maxLength="256"
                value={this.state.nombre}
                onChange={(e) => {
                  this.setState({
                    nombre: e.target.value,
                  });
                }}
              ></input>
              <label htmlFor="email-3" className="field-label-10">
                Apellidos *
              </label>
              <input
                type="text"
                className="text-field w-input"
                maxLength="256"
                value={this.state.apellidos}
                onChange={(e) => {
                  this.setState({
                    apellidos: e.target.value,
                  });
                }}
              ></input>
              <label htmlFor="email-3" className="field-label-10">
                DNI *
              </label>
              <input
                type="text"
                className="text-field w-input"
                maxLength="256"
                value={this.state.dni}
                onChange={(e) => {
                  this.setState({
                    dni: e.target.value,
                  });
                }}
              ></input>
              <label htmlFor="email-3" className="field-label-11">
                Teléfono *
              </label>
              <input
                type="text"
                className="text-field w-input"
                maxLength="256"
                value={this.state.telefono}
                onChange={(e) => {
                  this.setState({
                    telefono: e.target.value,
                  });
                }}
              ></input>
              <label htmlFor="email" className="field-label-12">
                E-mail *
              </label>
              <input
                type="email"
                className="text-field w-input"
                maxLength="256"
                value={this.state.email}
                onChange={(e) => {
                  this.setState({
                    email: e.target.value,
                  });
                }}
              ></input>
              <label htmlFor="email-3" className="field-label-13">
                IBAN * <span className="message-info">(24 dígitos)</span>
              </label>
              <span className="message-info">
                (Ejemplo: ES 01 23 4567 8901 2345678901)
              </span>
              <div className="IBAN-container">
                <input
                  type="text"
                  className="text-field w-input input-iban-2"
                  maxLength="2"
                  value={this.state.ibanCodigoPais}
                  onChange={(e) => {
                    this.setState({
                      ibanCodigoPais: e.target.value,
                    });
                    if (e.target.value.length === 2) {
                      e.target?.nextSibling?.focus();
                    }
                  }}
                ></input>
                <input
                  type="text"
                  className="text-field w-input input-iban-2"
                  maxLength="2"
                  value={this.state.ibanDigitoControl1}
                  onChange={(e) => {
                    this.setState({
                      ibanDigitoControl1: e.target.value,
                    });
                    if (e.target.value.length === 2) {
                      e.target?.nextSibling?.focus();
                    }
                  }}
                ></input>
                <input
                  type="text"
                  className="text-field w-input input-iban-4"
                  maxLength="4"
                  value={this.state.ibanEntidad}
                  onChange={(e) => {
                    this.setState({
                      ibanEntidad: e.target.value,
                    });
                    if (e.target.value.length === 4) {
                      e.target?.nextSibling?.focus();
                    }
                  }}
                ></input>
                <input
                  type="text"
                  className="text-field w-input input-iban-4"
                  maxLength="4"
                  value={this.state.ibanOficina}
                  onChange={(e) => {
                    this.setState({
                      ibanOficina: e.target.value,
                    });
                    if (e.target.value.length === 4) {
                      e.target?.nextSibling?.focus();
                    }
                  }}
                ></input>
                <input
                  type="text"
                  className="text-field w-input input-iban-2"
                  maxLength="2"
                  value={this.state.ibanDigitoControl2}
                  onChange={(e) => {
                    this.setState({
                      ibanDigitoControl2: e.target.value,
                    });
                    if (e.target.value.length === 2) {
                      e.target?.nextSibling?.focus();
                    }
                  }}
                ></input>
                <input
                  type="text"
                  className="text-field w-input input-iban-10"
                  maxLength="10"
                  value={this.state.ibanNumeroCuenta}
                  onChange={(e) => {
                    this.setState({
                      ibanNumeroCuenta: e.target.value,
                    });
                  }}
                ></input>
              </div>
              <label className="w-checkbox checkbox-field-2">
                <input
                  type="checkbox"
                  className="w-checkbox-input"
                  defaultChecked={this.state.certificadoDonacion}
                  onChange={() => {
                    this.setState({
                      certificadoDonacion: !this.state.certificadoDonacion,
                    });
                  }}
                ></input>
                <span
                  className="checkbox-label-2 w-form-label"
                  htmlFor="checkbox"
                >
                  ¿Desea recibir certificado de donación a efectos de
                  desgravación fiscal?
                </span>
              </label>
              {this.state.certificadoDonacion && (
                <div>
                  <label htmlFor="name" className="comprobarcuponcontenttitle">
                    Dirección *
                  </label>
                  <input
                    type="text"
                    className="text-field w-input"
                    maxLength="256"
                    value={this.state.direccion}
                    onChange={(e) => {
                      this.setState({
                        direccion: e.target.value,
                      });
                    }}
                  ></input>
                  <label htmlFor="name" className="comprobarcuponcontenttitle">
                    Localidad *
                  </label>
                  <input
                    type="text"
                    className="text-field w-input"
                    maxLength="256"
                    value={this.state.localidad}
                    onChange={(e) => {
                      this.setState({
                        localidad: e.target.value,
                      });
                    }}
                  ></input>
                  <label htmlFor="name" className="comprobarcuponcontenttitle">
                    Provincia *
                  </label>
                  <input
                    type="text"
                    className="text-field w-input"
                    maxLength="256"
                    value={this.state.provincia}
                    onChange={(e) => {
                      this.setState({
                        provincia: e.target.value,
                      });
                    }}
                  ></input>
                  <label htmlFor="name" className="comprobarcuponcontenttitle">
                    Código Postal *
                  </label>
                  <input
                    type="text"
                    className="text-field w-input"
                    maxLength="256"
                    value={this.state.cp}
                    onChange={(e) => {
                      this.setState({
                        cp: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              )}
              <label className="w-checkbox checkbox-field-2">
                <input
                  type="checkbox"
                  className="w-checkbox-input"
                  defaultChecked={this.state.toggleLegacy}
                  onChange={() => {
                    this.setState({
                      toggleLegacy: !this.state.toggleLegacy,
                    });
                  }}
                ></input>
                <span
                  className="checkbox-label-2 w-form-label"
                  htmlFor="checkbox"
                >
                  He leído y acepto los{" "}
                  <span className="text-span">
                    <a
                      href="https://www.fundacionelalto.es/politica-privacidad/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <strong className="bold-text">Términos legales</strong>
                    </a>
                  </span>
                  .
                </span>
              </label>
              <span className="noteSpan">(*) Campos obligatorios</span>
              {this.state.emptyFieldMessage && (
                <div className="alertmessage warningmessage">
                  <div className="dialogbox">
                    Existen <strong>campos obligatorios</strong> por rellenar.
                    Por favor, <strong>revíselos</strong>.
                  </div>
                  <img
                    src="images/close-grey.svg"
                    loading="lazy"
                    width="18"
                    alt=""
                    className="icon"
                    onClick={() => {
                      this.setState({ emptyFieldMessage: false });
                    }}
                  />
                </div>
              )}
              {this.state.legacyMessage && (
                <div className="alertmessage warningmessage">
                  <div className="dialogbox">
                    Debe <strong>aceptar</strong> los{" "}
                    <strong>términos legales</strong>.
                  </div>
                  <img
                    src="images/close-grey.svg"
                    loading="lazy"
                    width="18"
                    alt=""
                    className="icon"
                    onClick={() => {
                      this.setState({ legacyMessage: false });
                    }}
                  />
                </div>
              )}
              <div className="submit-button w-button" onClick={this.sendForm}>
                <span>Enviar</span>
              </div>
            </div>
          </div>
        )}
        {this.state.successfulRequest && (
          /*           <div className="alertmessage">
            <div className="dialogbox">
              ¡Su <strong>premio</strong> ha sido{" "}
              <strong>solicitado correctamente</strong>!
            </div>
            <img
              src="images/close.svg"
              loading="lazy"
              width="18"
              alt=""
              className="icon closeIcon"
              onClick={() => {
                this.setState({ successfulRequest: false });
              }}
            />
          </div> */
          <div className="formcomprobarcupon w-form">
            <h3 className="comprobarcuponheader">
              Solicitud <strong>recibida</strong>
              <strong className="bold-text-2"></strong>
            </h3>
            <div className="comprobarcuponcontent">
              <label
                htmlFor="name-6"
                className="comprobarcuponcontenttitle marginbottom CheckNumber-title"
              >
                ¡Su <strong>premio</strong> ha sido{" "}
                <strong>solicitado correctamente</strong>!
              </label>
              <div
                className="submit-button w-button"
                onClick={() => {
                  this.setState({
                    successfulRequest: false,
                    checkNumberContainer: true,
                  });
                }}
              >
                <span>Volver</span>
              </div>
              {this.state.winningNumber && (
                <div className="CheckNumber-winning-number-container">
                  <div className="alertmessage cuponpremiado">
                    <div className="dialogbox cuponpremiado">
                      <span className="text-span-3">¡Enhorabuena!</span>
                      <br></br>Tu <span className="text-span-4">número</span> ha
                      sido <span className="text-span-5">premiado</span>.
                    </div>
                    <div className="dialogbox cuponpremiado importepremio">
                      <span className="text-span-9">{this.state.premio} €</span>
                    </div>
                  </div>
                  <label
                    htmlFor="field"
                    className="comprobarcuponcontenttitle marginbottom cuponpremiado Checknumber-title"
                  >
                    Introduce el código de tu participación para solicitar tu
                    premio.
                  </label>
                  <input
                    type="text"
                    value={this.state.code}
                    onChange={(e) => {
                      this.setState({
                        code: e.target.value,
                      });
                    }}
                    className="text-field cuponpremiado w-input"
                    maxLength="256"
                    name="field"
                    data-name=""
                    placeholder="Escriba el código de la participación"
                    id="field"
                    required=""
                  ></input>
                  <div
                    className="submit-button w-button"
                    onClick={this.checkCode}
                  >
                    <span>Solicitar premio</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="footer">
          <div className="div-block-2">
            <img
              src="images/logo.png"
              loading="lazy"
              srcSet="images/logo.png 500w, images/logo.png 800w, images/logo.png 800w"
              sizes="160px"
              alt=""
              className="footerlogo"
            />
            <div className="footercontactofsfe">
              <div className="footersectiontitle bold">
                <strong className="bold">Contacto</strong>
              </div>
              <div className="itemcontactofsfe">
                <img
                  src="images/email-white.svg"
                  loading="lazy"
                  width="18"
                  alt=""
                  className="icon marginright"
                />
                <div className="footersectiontitle">
                  pagosloteria@loteriasolidaria.org
                </div>
              </div>
              <div className="itemcontactofsfe lastitem">
                <img
                  src="images/mobile-white.svg"
                  loading="lazy"
                  width="18"
                  alt=""
                  className="icon marginright"
                />
                <div className="footersectiontitle">621 08 50 11</div>
              </div>
            </div>
          </div>
          <div className="div-block CheckNumber-legacy-container">
            <div className="footersectiontitle fullwidth">
              <a
                href="https://www.fundacionelalto.es/politica-privacidad/"
                target="_blank"
                rel="noreferrer"
              >
                Términos legales
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CheckNumber;
